import {
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import store from 'store/dist/store.modern';
import {PlaylistModalVariants} from '../../configs/pages/playlists/modals';
import {Button, Modal} from '../../components/core';
import {
    AddPlaylistVideoDocument,
    GetChangelogDocument,
    GetPlaylistVideosFiltersDocument,
    GetPlaylistVideosFiltersQuery,
    VideoVersion,
    GetPlaylistVideosAddDocument,
} from '../../graphql-types';
import useLocalStorage from '../../hooks/useLocalStorage';
import {Filter, FilterDimensionsData} from '../../interfaces/filters';
import FullFilter from '../../components/modules/fullFilter/fullFilter';
import useFilter from '../../hooks/useFilter';
import ButtonGroup from '../../components/core/button/buttonGroup';
import {AddIcon} from '../../components/core/icons';
import getFilterAttributes from '../../helpers/filters/getFilterAttributes';
import {tableAddPlaylistVideoColumns, tableAddPlaylistVideoSubColumns} from '../../configs/pages/addPlaylistVideo';
import Table from '../../components/core/table/table';
import TableFooter from '../../components/core/table/tableFooter';
import toastify from '../../helpers/toast/toastify';
import * as styles from './addPlaylistVideo.module.css';

interface IAddPlaylistVideoModalProps {
    setModal: Dispatch<SetStateAction<PlaylistModalVariants | null>>,
    playlist: {
        id: string,
        name: string,
        channelId: string,
        languageId: string,
    },
}

const AddPlaylistVideoModal = ({setModal, playlist}: IAddPlaylistVideoModalProps) => {
    const [paging, setPaging] = useState({limit: store.get('userSettings').showPerPage || 10, page: 1});
    const [localStorageFilters, setLocalStorageFilters] = useLocalStorage<Filter | null>('filterVideosTable');
    const [searchQuery, setSearchQuery] = useState('');
    const [addPlaylistVideo] = useMutation(AddPlaylistVideoDocument);
    const {data: videoFiltersData} = useQuery(GetPlaylistVideosFiltersDocument);
    const [selectedRows, setSelectedRows] = useState<string[] | null>(null);
    const {
        filters,
        loaded: filtersLoaded,
        toggleFilter,
        setFilter,
        setAllFilters,
    } = useFilter(localStorageFilters || undefined);

    const getQueryVariables = (resetPaging?: boolean) => ({
        paging: resetPaging ? {limit: paging.limit, page: 1} : paging,
        filter: {
            channelIds: [playlist.channelId],
            languageIds: [playlist.languageId],
            version: filters.versionAttributes as VideoVersion[],
            themeIds: filters.themeAttributes as string[],
            typeIds: filters.typeAttributes as string[],
            uploadPolicyIds: filters.uploadPolicyAttributes as string[],
            createdAt: {
                from: filters.createdAt?.[0] || null,
                to: filters.createdAt?.[1] || null,
            },
            fulltext: searchQuery,
            seriesGroupIds: filters.seriesGroupAttributes as string[],
            seasonSubgroupIds: filters.seasonSubgroupAttributes as string[],
        },
    });

    const [
        fetchMutations, {
            data,
            error,
            loading,
        },
    ] = useLazyQuery(GetPlaylistVideosAddDocument, {
        fetchPolicy: 'cache-and-network',
    });

    const handleAddVideos = () => {
        if (!playlist || !playlist.id) {
            return;
        }

        addPlaylistVideo({
            variables: {
                playlistId: playlist.id,
                uploadIds: selectedRows || [],
            },
            onCompleted(returnData) {
                if (!returnData) {
                    toastify({type: 'error', text: 'Error adding video(s) to playlist'});

                    return;
                }

                toastify({
                    type: 'success',
                    text: 'Video(s) added to playlist',
                });
                setModal(null);
            },
            onError(returnError) {
                toastify({type: 'error', text: `Error adding video(s) to playlist: ${returnError.message}`});
            },
            refetchQueries: [
                {
                    query: GetChangelogDocument,
                    variables: {
                        filter: {
                            playlistIds: [playlist.id],
                        },
                    },
                },
            ],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    const getBulkActionButtons = (
        <ButtonGroup>
            <Button
                text="Add to playlist"
                icon={AddIcon}
                onClick={() => handleAddVideos()}
                size="tiny"
                color="white"
            />
        </ButtonGroup>
    ) as ReactNode;

    const videoFilters = useMemo(() => {
        if (!videoFiltersData) {
            return {};
        }

        const toReturn = getFilterAttributes<GetPlaylistVideosFiltersQuery>(videoFiltersData);

        toReturn.createdAt = {
            name: 'Created at',
            type: 'dateRange',
            minMax: [data?.videoStats.minMutationCreatedAt as string, data?.videoStats.maxMutationCreatedAt as string],
            values: [
                {
                    value: null,
                    name: 'dateFrom',
                }, {
                    value: null,
                    name: 'dateTo',
                },
            ],
        };

        return toReturn;
    }, [videoFiltersData, data]);

    const tableColumns = tableAddPlaylistVideoColumns();

    const handleApplyFilters = (): void => {
        setLocalStorageFilters(filters);

        fetchMutations({
            variables: {
                ...getQueryVariables(true),
                id: playlist.id,
            },
        });
    };

    useEffect(() => {
        if (!filtersLoaded) {
            return;
        }

        fetchMutations({
            variables: {
                ...getQueryVariables(true),
                id: playlist.id,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersLoaded]);

    useEffect(() => {
        fetchMutations({
            variables: {
                ...getQueryVariables(true),
                id: playlist.id,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        fetchMutations({
            variables: {
                ...getQueryVariables(),
                id: playlist.id,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging]);

    return (
        <Modal
            title={(
                <>
                    <span>Choose videos to add to playlist /&nbsp;</span><strong>{playlist?.name}</strong>
                </>
            )}
            closeModal={() => setModal(null)}
            classNames={{
                title: styles.title,
            }}
        >
            <FullFilter
                data={videoFilters as FilterDimensionsData}
                filters={filters}
                filterMethods={{
                    toggleFilter,
                    setAllFilters,
                    applyFilters: handleApplyFilters,
                    setFilter,
                }}
                search={{
                    query: searchQuery,
                    applyQuery: setSearchQuery,
                }}
                inModal={true}
                render={() => (
                    <>
                        <Table
                            settings={{
                                itemsName: 'videos',
                                isCompact: true,
                                isStriped: true,
                            }}
                            bulkActions={{
                                enabled: true,
                                selectedIds: selectedRows,
                                setSelectedIds: setSelectedRows,
                                buttons: getBulkActionButtons,
                                pathToId: 'id',
                                rootClass: 'tight',
                            }}
                            columns={tableColumns}
                            data={data?.playlistAddUploads?.results.map(result => {
                                const {mutations} = result;

                                if (mutations.length > 1) {
                                    return {
                                        ...result,
                                        subRows: mutations.map(mutation => ({
                                            ...mutation,
                                        })),
                                    };
                                }

                                return result;
                            }) || []}
                            dataLoading={{
                                active: loading,
                                text: 'Fetching videos...',
                            }}
                            dataError={{
                                active: !!error,
                                text: error?.message || 'error',
                            }}
                            subRowStructure={tableAddPlaylistVideoSubColumns()}
                        />
                        {data && (
                            <TableFooter
                                rows={data.playlistAddUploads.paging.count}
                                paging={{
                                    limit: data.playlistAddUploads.paging.limit,
                                    page: data.playlistAddUploads.paging.page,
                                }}
                                setPaging={setPaging}
                                itemsName="videos"
                            />
                        )}
                    </>
                )}
            />
        </Modal>
    );
};

export default AddPlaylistVideoModal;
